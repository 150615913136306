import "./App.css";
import AppContext from "./AppContext";
import Header from "./components/header";
import Main from "./components/main";
import SpecialUnderlay from "./components/specialUnderlay";
import About from "./components/about";
import Experience from "./components/experience";
import Projects from "./components/projects";
import { useEffect, useRef, useState } from "react";

function App() {
  const [data, setData] = useState({
    basic: null,
    about: null,
    experience: null,
    projects: null,
  });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const SECTIONS = [
    { name: "about", ref: useRef(null), component: About, data: data.about },
    {
      name: "experience",
      ref: useRef(null),
      component: Experience,
      data: data.experience,
    },
    {
      name: "projects",
      ref: useRef(null),
      component: Projects,
      data: data.projects,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_DATA_URL);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateCoordinates = (e) => {
    if (screenWidth >= 1024) setCoordinates({ x: e.clientX, y: e.clientY });
  };

  return (
    <AppContext.Provider
      value={{ sections: SECTIONS, basicData: data.basic, screenWidth }}
    >
      <div
        className="app-wrapper relative bg-slate-900 text-slate-400 antialiased selection:bg-teal-300 selection:text-teal-900"
        onClick={(e) => updateCoordinates(e)}
        onMouseMove={(e) => updateCoordinates(e)}
      >
        <SpecialUnderlay coordinates={coordinates} />
        <div className="xl:container mx-auto py-12 md:py-20 lg:py-0 px-6 md:px-12 lg:px-24 lg:flex lg:gap-4">
          {data.basic && data.about && data.experience && data.projects ? (
            <>
              <Header />
              <Main />
            </>
          ) : (
            <div className="h-screen"></div>
          )}
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
